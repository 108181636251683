import { useQuery } from '@apollo/client';
import { useHubble } from '@seek/hubble';

import { useAppConfig } from 'src/config/appConfig';
import type {
  GetRecommendations,
  GetRecommendationsVariables,
} from 'src/modules/graphql/queries/types/GetRecommendations';
import { useSelector } from 'src/store/react';
import { selectSmarterSearchCluster } from 'src/store/selectors';

import { GET_RECOMMENDATIONS } from '../graphql/queries/recommendations';

export const useRecommendations = () => {
  const { zone, locale } = useAppConfig();
  const hubble = useHubble();
  const visitorId = hubble.visitorId();
  const smarterSearchCluster = useSelector(selectSmarterSearchCluster);

  const { data, loading } = useQuery<
    GetRecommendations,
    GetRecommendationsVariables
  >(GET_RECOMMENDATIONS, {
    variables: {
      zone,
      visitorId,
      limit: 3,
      cluster: smarterSearchCluster,
      locale,
    },
    ssr: false,
  });

  const recommendedJobs = data?.viewer?.recommendations?.recommendedGlobalJobs;
  const jobs = recommendedJobs?.globalJobs;

  // NOTE: In order to preserve current functionality until the homepage Recs
  // component/layout is refactored in the very near future, we are harcoding the totalCount.
  // Very high portion of users see '50' as the count, because chalice-experience calculated
  // totalCount as the amount of recs it received from the recommendations-api
  const totalCount = jobs?.length ? 50 : undefined;

  return {
    jobs,
    loading,
    totalCount,
    solMetadata: recommendedJobs?.solMetadata,
  };
};
