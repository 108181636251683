import "src/components/HomePage/HomePageBanner/Campaign/Campaign.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.11_babel-plugin-macros@3.1.0_less@4._zkosotvipxlubd62fyqljosvgq/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/Campaign/Campaign.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+VVy3LiMBC88xVzSVV8cMpPMOKSP0kJW9ja2JIjxKN2i3/fQjwykkGG1N72BtMtTU/32H6r4iTf6Br+TABWLduHFVes1FwKAluqXsPwxJgHi8lh8nb60xh6T6uKizrUsre5NFggeCm1lp3NWFqMlq20jZcWrnjdOIQKy+FGTsOGNGbu6bgIb6Ergy5p+VkruRFVyDtaM5sTu5w1/80IlHLLlIMo1jOqCQh5/ungvVzzobEJnuSXmQSdKWUrlX0gwgc+zYGO7sMdr3RDICnKBuHtKVgp9Fk4vikzs7VcsKs98cIXbIpbd+bqGwJzzBLuRDc8nhod5wEwMDPAregKN5Yx8y+xCU25QPqk0bdruGbhuqclO16xU7RHnN5wrk8CASV3C1RhBJI86ve4trzo/YhpvdE7EQUYpn649MOVC1vBfN2QGyq2ZWrNvE1SfxNr8ZTbpJTtphP4ghWBPHJsYQTiKHrBpYxAntus1G09tZTNCKTuxQWBOHFqA5PzwInIOfCs7SdH3jtWcQrrUjEmgIoKXtEDaSwIjFvWOt0xz7aqOAv07NUptNHNGh1yZEyAw/cEX/cmwGvmkeRqvvOwoI7K6bgikF3iPoxmkA0ycO/LjiTX6/S6IFbjNBlUZwSSYTU5pv9i1woCs9yvm4uL7ln2891Jov96d/6pZndbvj8B9mLEA8sf3iv8TvNMXwSPbc58njyzOc/m4/EGvc7t6dJHp/NENx2LbvqgP3HyyGt5EDL6fHk20FU5mNMl/HxOX2zOZ/8wOfwFGzO2xO4LAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.11_babel-plugin-macros@3.1.0_less@4._zkosotvipxlubd62fyqljosvgq/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = 'd125utj';
export var backgroundColour = 'var(--d125ut0)';
export var backgroundImage = 'var(--d125ut1)';
export var backgroundPosition = 'var(--d125ut2)';
export var containerDirection = 'var(--d125ut9)';
export var containerLayout = 'd125utg';
export var contentGutter = 'd125uth';
export var contentPaddingBottom = 'var(--d125utb)';
export var contentPaddingLeft = 'var(--d125utc)';
export var contentPaddingRight = 'var(--d125utd)';
export var contentPaddingTop = 'var(--d125uta)';
export var image = 'd125uti';
export var imageHeightVar = 'var(--d125ute)';
export var imageMinHeightVar = 'var(--d125utf)';
export var imageSubtitle = 'd125utn';
export var multiCampaign = 'd125utp';
export var nowrap = 'd125uto';
export var reverse = 'd125utq';
export var standaloneCampaign = 'd125utr';
export var subtitle = 'd125utm';
export var subtitleBackgroundImage = 'var(--d125ut6)';
export var subtitleColour = 'var(--d125ut5)';
export var subtitleImageHeight = 'var(--d125ut8)';
export var subtitleImageSize = 'var(--d125ut7)';
export var title = 'd125utl';
export var titleFontSize = 'var(--d125ut4)';
export var titleLimit = 'd125utk';
export var titleTopPadding = 'var(--d125ut3)';