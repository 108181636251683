import "src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.11_babel-plugin-macros@3.1.0_less@4._zkosotvipxlubd62fyqljosvgq/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/HomePage/HomePageBanner/GenericAsiaBanner/GenericAsiaBanner.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA+2SwXKCMBCG7z5FjnKIg6AoeOmbdGKyQlpIMiEKY8d37xhlagKIvXuD7LeZ/b/s4nMpw7bOzwz9zBA6lNBixjVQw6XI0InoOcYdsw52s8ts0f2CbVGEMS5ybKTy+STYPQB7aYysfGbjMCUcjE9sHULzvOghqTvYwQ5WwBBK7G0VF3i4vrf1hjNT+CVqS3tCv3Mtj4JhXpEcfGrpUzU/Q4aoPIH2KhoUEJMhIe+fXl3Jmg+9Q+TGzW3chz4qS6n9ptBtKqYeL3Z5bvmm4AZwrQiF69iNJsqhviz1sDHX4OWxEjvnmGQoDkPVuqc0Q1Gy8k83f4OR/GgaEQYukEwB2ykg9YFb9o8KGCeophpAICIYmlekxffdWF8DBDavF39UwJiCMQlPNcSBj4yIeEHFUxk35DIihItOyGb1FuIISdPov0KmL11Gr+6dls2Q4zQedByvkhccJ9OO+0jPcR/pOU46x7+N7uSaoQYAAA==\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.11_@types+node@20.14.11_babel-plugin-macros@3.1.0_less@4._zkosotvipxlubd62fyqljosvgq/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var background = '_1o0xsgzg';
export var backgroundColour = 'var(--_1o0xsgz0)';
export var backgroundImage = 'var(--_1o0xsgz1)';
export var backgroundPosition = 'var(--_1o0xsgz2)';
export var containerDirection = 'var(--_1o0xsgz5)';
export var containerLayout = '_1o0xsgzd';
export var contentGutter = '_1o0xsgze';
export var contentPaddingBottom = 'var(--_1o0xsgz7)';
export var contentPaddingLeft = 'var(--_1o0xsgz8)';
export var contentPaddingRight = 'var(--_1o0xsgz9)';
export var contentPaddingTop = 'var(--_1o0xsgz6)';
export var image = '_1o0xsgzf';
export var imageHeightVar = 'var(--_1o0xsgza)';
export var imageMinHeightVar = 'var(--_1o0xsgzb)';
export var imageWidthVar = 'var(--_1o0xsgzc)';
export var nowrap = '_1o0xsgzi';
export var responsive = '_1o0xsgzj';
export var title = '_1o0xsgzh';
export var titleFontSize = 'var(--_1o0xsgz4)';
export var titleTopPadding = 'var(--_1o0xsgz3)';