import { useTranslations } from '@vocab/react';
import { TextLink } from 'braid-design-system';
import { type ReactNode, useCallback, useEffect } from 'react';

import { useAnalyticsFacade } from 'src/modules/AnalyticsFacade';
import { useDispatch } from 'src/store/react';
import { saveSolReferencesForLoggedInHomeRecsAction } from 'src/store/results';

import DashboardColumn, {
  type JobItem,
} from '../DashboardColumn/DashboardColumn';

import translations from './.vocab';
import RecommendedJobItem from './RecommendedJobItem';
import useRecommendedJobsDashboard from './useRecommendedJobsDashboard';

const RecommendedJobsDashboard = () => {
  const { t } = useTranslations(translations);
  const { displayState, jobs, mapJobItemProps, totalCount } =
    useRecommendedJobsDashboard();
  const dispatch = useDispatch();

  useEffect(() => {
    if (jobs?.length) {
      dispatch(saveSolReferencesForLoggedInHomeRecsAction(jobs));
    }
  }, [dispatch, jobs]);

  const label = t('Recommended jobs', { totalCount: totalCount ?? 0 });

  const viewAllLinkProps = {
    analytics: 'dashboard-all-recommended-jobs-click',
    location: '/recommended',
    linkContext: {
      linkSection: 'Recommended Jobs',
    },
  };

  const renderJobItem = useCallback(
    (job: JobItem, index: number) => {
      const props = mapJobItemProps(job, index);
      return <RecommendedJobItem {...props} key={props.job.id} />;
    },
    [mapJobItemProps],
  );

  const analyticsFacade = useAnalyticsFacade();
  const profileLink = '/profile/me';
  const profileLinkOnClick = useCallback(() => {
    analyticsFacade.linkClicked({
      href: profileLink,
      linkName: 'dashboard-recommended-jobs-profile',
    });
  }, [analyticsFacade]);

  return (
    <DashboardColumn
      type="recommendedJobs"
      dataAutomation="recommendedJobsDashboard"
      label={label}
      displayState={displayState}
      totalCount={totalCount}
      errorLinkProps={viewAllLinkProps}
      viewAllLinkProps={{
        ...viewAllLinkProps,
        screenReaderText: t('recommended jobs'),
      }}
      jobs={jobs}
      renderJobItem={renderJobItem}
      emptyMessage={t('Complete your profile...', {
        NavLink: (children: ReactNode) => (
          <TextLink
            weight="weak"
            hitArea="large"
            href={profileLink}
            key="profile-link"
            onClick={profileLinkOnClick}
          >
            {children}
          </TextLink>
        ),
      })}
    />
  );
};

export default RecommendedJobsDashboard;
