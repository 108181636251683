import { CompanyProfileCarousel } from '@seek/branding-assets';
import type { Locale } from '@seek/melways-sites';
import { metrics } from '@seek/metrics-js';
import { Box, PageBlock } from 'braid-design-system';
import { useEffect, useState, type ComponentProps } from 'react';

import { siteSection } from 'src/config';
import { useAppConfig } from 'src/config/appConfig';
import type {
  ExperimentsState,
  TSeekExperiment,
} from 'src/store/experiments/types';
import { useSelector } from 'src/store/react';

interface Props {
  /**
   * Allows the parent to control the whitespace without having to
   * expose whether or not Company Profiles are available outside of
   * this component.
   */
  sectionSpace?: ComponentProps<typeof Box>['paddingTop'];
}

const experimentsMapper = (experiments: ExperimentsState) =>
  experiments
    ? Object.values(experiments).map(
        ({ name, variation, num }: TSeekExperiment) => ({
          id: name,
          variant: variation.name,
          num,
        }),
      )
    : [];

export const CPCarousel = ({ sectionSpace }: Props) => {
  const { language, zone, country } = useAppConfig();
  const [isError, setError] = useState(false);
  const payload = useSelector((state) => ({
    brand: state.appConfig.brand,
    currentPage: 'home',
    isLoggedIn: Boolean(state.user.authenticated),
    siteCountry: country as Locale,
    siteLanguage: language,
    loginId: state.user.seekerId ? JSON.stringify(state.user.seekerId) : 'NULL',
    zone,
    experiments: experimentsMapper(state.experiments),
    siteSection,
    cardType: 'Company',
    carouselSection: 'Company Profile',
  }));

  useEffect(() => {
    if (isError) {
      metrics.count('ui_error', [`type:company_profile_carousel`]);
    }
  }, [isError]);

  return !isError ? (
    <Box paddingTop={sectionSpace}>
      <PageBlock data={{ automation: 'cpCarouselContainer' }}>
        <CompanyProfileCarousel
          hideReviews={true}
          analyticsQueryParams="cid=sk-home-carousel"
          onError={() => setError(true)}
          payload={payload}
        />
      </PageBlock>
    </Box>
  ) : null;
};
